<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <button type="button" id="sidebarCollapse" class="btn ">
          <i class="fa fa-bars"></i>
          <span class="sr-only">Toggle Menu</span>
        </button>
        <a class="nav-link" href="#">Home</a>
        <button
          class="btn btn-dark d-inline-block d-lg-none ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fa fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#"> Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
//import axios from "axios"
export default {
  //      components: {
  //          BIcon,
  //          BIconPersonFill,
  //          BIconEnvelope
  //   },
  data: () => {
    return {};
  },
  methods: {}
};
</script>
<style scoped>
.header {
  background-color: lightslategray;
  border-bottom: 1px solid black;
}
.btn {
  color: white !important;
}
a {
  color: white !important;
}
</style>
