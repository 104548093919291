<template>
  <div class="sidebar">
    <ul>
      <li>
        <span class="fa-stack fa-lg pull-left"
          ><i class="fa fa-cart-plus fa-stack-1x "></i></span
        ><span class="pt">Services</span>
      </li>
      <li>
        <span class="fa-stack fa-lg pull-left"
          ><i class="fa fa-wrench fa-stack-1x "></i></span
        ><span class="pt">Events</span>
      </li>
      <li>
        <span class="fa-stack fa-lg pull-left"
          ><i class="fa fa-cart-plus fa-stack-1x "></i></span
        ><span class="pt">Books</span>
      </li>
      <li>
        <span class="fa-stack fa-lg pull-left"
          ><i class="fa fa-wrench fa-stack-1x "></i></span
        ><span class="pt">Register</span>
      </li>
    </ul>
  </div>
</template>
<script>
//import axios from "axios"
export default {
  data: () => {
    return {};
  },
  methods: {}
};
</script>
<style scoped>
.sidebar {
  width: 18%;
  background: lightslategray;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 4rem;
  z-index: -1;
}
ul {
  list-style-type: none;
  padding-inline-start: 0px;
}
li {
  display: flex;
  color: white;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
  padding-left: 1rem;
}
.pt {
  padding-top: 10px;
  padding-left: 10px;
}
</style>
