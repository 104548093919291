<template>
  <div>
    <Header></Header>
    <Sidebar></Sidebar>
  </div>
</template>
<script>
//import axios from "axios"
//import { BIcon, BIconPersonFill, BIconEnvelope } from 'bootstrap-vue'
import Sidebar from "./Sidebar";

import Header from "./Header";

export default {
  components: {
    Header,
    Sidebar
  },
  data: () => {
    return {
      username: null,
      password: null
    };
  },
  methods: {}
};
</script>
